.spaceship-container {
  width: 350px;
  min-width: 350px;
  height: 400px;
  position: relative;
}
.me {
  width: 350px;
  position: absolute;
  top: 0;
  z-index: 10;
  animation: hover infinite 5s ease-in-out;
}

.ring-1 {
  width: 200px;
  position: absolute;
  top: 280px;
  animation: hover infinite 1s ease-in-out;
}
.ring-2 {
  width: 100px;
  position: absolute;
  top: 330px;
  animation: hover infinite 1s ease-in-out;
  /* animation-delay: 3s; */
}
.ring-3 {
  width: 50px;
  position: absolute;
  top: 360px;
  animation: hover infinite 1s ease-in-out;
}
.png-base64 {
}
.center-container {
}

.bio {
  min-width: 200px;
  color: #000;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
}

.links-container img {
  width: 30px;
  margin: 10px;
  opacity: 0.5;
}

.links-container img:hover {
  width: 30px;
  margin: 10px;
  opacity: 1;
}
@keyframes hover {
  0% {
    /* transform: skew(10deg, 10deg); */
    transform: translateY(0px);
    /* transform: scale(1,1); */
  }
  50% {
    /* transform: skew(-20deg, -20deg); */
    transform: translateY(7px);
    /* transform: scale(1.1,1.1); */
  }
  100% {
    transform: translateY(0px);
  }
}
